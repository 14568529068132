/************************************************************/
/* Global component styles */

.page-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eee;
  padding: .5em .5em;
  border: 1px solid #ddd;
  z-index: 2;
}

.page-header-title {
  font-weight: 600;
}

.page-header-options {
  display: flex;
  gap: 4px;
}

.page-header-options svg {
  margin-bottom: -3px;
}

/************************************************************/
/* List styles */

/*
We are now using a solsys-reactjs ListView component which
comes with its own styling
*/

/************************************************************/
/* Options styles */

.options-tools {
  display: flex;
  gap: 4px;
}

.options-section h3 {
  margin-top: 0;
  margin-bottom: .8em;
}

.options-section {
  margin-top: 1em;
}

.options-section .option {
  margin-bottom: .4em;
}

.option > * {
  display: block;
}

/************************************************************/
/* Add/edit page styles */

.add-edit {
  padding: .5em;
}

.add-edit > * {
  margin-bottom: 10px;
}

.edit-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin-bottom: 10px;
}

.edit-property > label {
  display: block;
}
.edit-property > textarea {
  display: block;
  width: 100%;
}

/************************************************************/
/* Mobile overrides */

.mobile .page-header,
.mobile .options-menu {
  /* top: 40px; */
}

/*********************************************************/
/* Document Requirements */

.required-document-list {
  max-width: 600px;
  margin: 20px auto;
  padding: 0;
}

.required-document-item {
  background: #fff;
  margin-bottom: 10px;
  padding: 15px;
  border-left: 5px solid #2ecc71;
}

.required-document-item.invalid {
  border-left: 5px solid #e74c3c;
}

.required-document-info {
  display: flex;
  flex-direction: column;
}

.required-document-name {
  margin: 0 0 5px 0;
  padding: 0;
}

.required-document-status {
  margin: 0 0 5px 0;
  padding: 0;
  font-size: 0.9em;
}

.required-document-status.valid {
  color: #2ecc71;
}

.required-document-status.invalid {
  color: #e74c3c;
}

.required-document-filename {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  color: #3498db;
}
